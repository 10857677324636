import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from '@shared/guards/auth.guard';

const childrenRoutes: VexRoutes = [
  {
    path: 'estadisticas',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'productos',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'almacenes',
    loadChildren: () => import('./pages/warehouse/warehouse.module').then(m => m.WarehouseModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'categorias',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'proveedores',
    loadChildren: () => import('./pages/provider/provider.module').then(m => m.ProviderModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'compras',
    loadChildren: () => import('./pages/purcharse/purcharse.module').then(m => m.PurcharseModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'ventas',
    loadChildren: () => import('./pages/sales/sales.module').then(m => m.SalesModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'reportes',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'tienda-online',
    loadChildren: () => import('./pages/ecommerce/ecommerce.module').then(m => m.EcommerceModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then(m => m.StockModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

const routes: VexRoutes = [
  {
    path: '',
    redirectTo: 'tienda-online',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    data: {
      containerEnabled: true
    }
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}