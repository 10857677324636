import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { IconsService } from '@shared/services/icons.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: NavigationItem[] = [];
  rol: number;

  private _cartCountSubject = new BehaviorSubject<number>(this.getCartCount());
  cartCount$ = this._cartCountSubject.asObservable(); // Se puede suscribir a este observable

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor() { }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  buildMenuBasedOnRole(rol: number) {
    const cartCount = this.getCartCount();
    if (rol == 1) {
      this.items = [
        {
          type: 'link',
          label: 'Estadísticas',
          route: 'estadisticas',
          icon: IconsService.prototype.getIcon('icDashboard')
        },
        {
          type: 'link',
          label: 'Almacenes',
          route: 'almacenes',
          icon: IconsService.prototype.getIcon('icWarehouse')
        },
        {
          type: 'dropdown',
          label: 'Catálogo',
          icon: IconsService.prototype.getIcon('icManage'),
          children: [
            {
              type: 'link',
              label: 'Categorías',
              route: 'categorias',
            },
            {
              type: 'link',
              label: 'Productos',
              route: 'productos',
            }
          ]
        },
        {
          type: 'link',
          label: 'Proveedores',
          route: 'proveedores',
          icon: IconsService.prototype.getIcon('icProvider')
        },
        {
          type: 'dropdown',
          label: 'Operaciones',
          icon: IconsService.prototype.getIcon('icSales'),
          children: [
            {
              type: 'link',
              label: 'Compras',
              route: 'compras',
            },
            {
              type: 'link',
              label: 'Ventas',
              route: 'ventas',
            }
          ]
        },
        {
          type: 'link',
          label: 'Stock',
          route: 'stock',
          icon: IconsService.prototype.getIcon('icStock')
        },
        {
          type: 'link',
          label: 'Pedidos',
          route: 'pedidos',
          icon: IconsService.prototype.getIcon('icStore')
        },
        {
          type: 'link',
          label: 'Clientes',
          route: 'clientes',
          icon: IconsService.prototype.getIcon('icClient')
        },
        {
          type: 'link',
          label: 'Usuarios',
          route: 'usuarios',
          icon: IconsService.prototype.getIcon('icUsers')
        },
        {
          type: 'dropdown',
          label: 'Reportes',
          icon: IconsService.prototype.getIcon('icDescription'),
          children: [
            {
              type: 'link',
              label: 'Ventas eliminadas',
              route: 'reportes/ventas-eliminadas',
            },
            {
              type: 'link',
              label: 'Ventas a facturar',
              route: 'reportes/ventas-a-facturar',
            },
            {
              type: 'link',
              label: 'Compras eliminadas',
              route: 'reportes/compras-eliminadas',
            },
            {
              type: 'link',
              label: 'Productos eliminados',
              route: 'reportes/productos-eliminados',
            },
            {
              type: 'link',
              label: 'Proveedores eliminados',
              route: 'reportes/proveedores-eliminados',
            },
            {
              type: 'link',
              label: 'Clientes eliminados',
              route: 'reportes/clientes-eliminados',
            },
          ]
        },
      ];
    }
    else if (rol == 2) {
      this.items = [
        {
          type: 'dropdown',
          label: 'Catálogo',
          icon: IconsService.prototype.getIcon('icManage'),
          children: [
            {
              type: 'link',
              label: 'Categorías',
              route: 'categorias',
            },
            {
              type: 'link',
              label: 'Productos',
              route: 'productos',
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Operaciones',
          icon: IconsService.prototype.getIcon('icSales'),
          children: [
            {
              type: 'link',
              label: 'Compras',
              route: 'compras',
            },
            {
              type: 'link',
              label: 'Ventas',
              route: 'ventas',
            }
          ]
        },
        {
          type: 'link',
          label: 'Clientes',
          route: 'clientes',
          icon: IconsService.prototype.getIcon('icClient')
        },
      ];
    }
    else {
      this.items = [
        {
          type: 'link',
          label: 'Productos',
          route: 'tienda-online',
          icon: IconsService.prototype.getIcon('icProduct')
        },
        {
          type: 'link',
          label: 'Mis Datos',
          route: 'tienda-online/mis-datos',
          icon: IconsService.prototype.getIcon('icDatos')
        },
        {
          type: 'link',
          label: `Carrito (${cartCount})`,
          route: 'tienda-online/carrito',
          icon: IconsService.prototype.getIcon('icCarrito')
        },
        {
          type: 'link',
          label: 'Mis Pedidos',
          route: 'tienda-online/mis-pedidos',
          icon: IconsService.prototype.getIcon('icPedidos')
        },
      ];
    }
  }

  getCartCount(): number {
    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    return cart.reduce((acc: number, item: any) => acc + (item.quantity || 1), 0);
  }

  updateCartCount(): void {
    const newCartCount = this.getCartCount();
    this._cartCountSubject.next(newCartCount);
    window.location.reload();
  }
}
