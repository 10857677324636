import { HttpHeaders } from "@angular/common/http"

export const endpoint = {
    //AUTH MODULE
    LOGIN: 'Auth/Login',
    LOGIN_GOOGLE: 'Auth/LoginWithGoogle',
    LOGIN_CLIENT: 'Auth/LoginClient',
    LOGIN_GOOGLE_CLIENT: 'Auth/LoginWithGoogleClient',

    // CATEGORY MODULE
    LIST_CATEGORIES: 'Category/Filters',
    LIST_SELECT_CATEGORIES: 'Category/Select',
    CATEGORY_BY_ID: 'Category/',
    CATEGORY_REGISTER: 'Category',
    CATEGORY_EDIT: 'Category/',
    CATEGORY_REMOVE: 'Category/',

    // CLIENT MODULE
    LIST_CLIENTS: 'Client/Filters',
    LIST_SELECT_CLIENTS: 'Client/Select',
    CLIENT_BY_ID: 'Client/',
    CLIENT_REGISTER: 'Client',
    CLIENT_EDIT: 'Client/',
    CLIENT_REMOVE: 'Client/',
    CLIENT_TOTAL_COUNT: 'Client/totalCount',
    CLIENT_DELETES: 'Client/GetClientsDelete',

    // DOCUMENT TYPE MODULE
    LIST_DOCUMENT_TYPE: 'DocumentType',

    // ORDER MODULE
    LIST_ORDERS: 'Order/Filters',
    LIST_ORDER_BY_CLIENT: 'Order/Client',
    ORDER_BY_ID: 'Order/',
    ORDER_REGISTER: 'Order',
    ORDER_PROCESSED: 'Order/Process/',
    ORDER_COMPLETE: 'Order/Complete/',
    ORDER_CANCEL: 'Order/Cancel/',

    // PRODUCT MODULE
    LIST_PRODUCTS: 'Product/Filters',
    PRODUCT_BY_ID: 'Product/',
    PRODUCT_REGISTER: 'Product',
    PRODUCT_EDIT: 'Product/',
    PRODUCT_REMOVE: 'Product/',
    PRODUCT_TOTAL_COUNT: 'Product/totalCount',
    PRODUCT_DELETES: 'Product/GetProductsDelete',

    // PRODUCT STOCK MODULE
    LIST_PRODUCT_STOCK: 'ProductStock/',

    // PROVIDER MODULE
    LIST_PROVIDERS: 'Provider/Filters',
    LIST_SELECT_PROVIDERS: 'Provider/Select',
    PROVIDER_BY_ID: 'Provider/',
    PROVIDER_REGISTER: 'Provider',
    PROVIDER_EDIT: 'Provider/',
    PROVIDER_REMOVE: 'Provider/',
    PROVIDER_TOTAL_COUNT: 'Provider/totalCount',
    PROVIDER_DELETES: 'Provider/GetProvidersDelete',

    // USER MODULE
    LIST_USERS: 'User/Filters',
    USER_BY_ID: 'User/',
    USER_REGISTER: 'User',
    USER_EDIT: 'User/',
    USER_REMOVE: 'User/',
    USER_CHANGE_PASSWORD: 'User/ChangePassword',

    // PURCHARSE MODULE
    LIST_PURCHARSES: 'Purcharse/Filters',
    PURCHARSE_BY_ID: 'Purcharse/',
    PURCHARSE_REGISTER: 'Purcharse',
    PURCHARSE_CANCEL: 'Purcharse/',
    PURCHARSE_DELETES: 'Purcharse/GetPurcharseDelete',

    // SALE MODULE
    LIST_SALES: 'Sale/Filters',
    SALE_BY_ID: 'Sale/',
    SALE_REGISTER: 'Sale',
    SALE_CANCEL: 'Sale/',
    SALE_PRODUCT_STOCK_BY_WAREHOUSE: 'Sale/ProductStockByWarehouse',
    SALE_GET_LOW_STOCK_BY_WAREHOUSE: 'Sale/GetLowStockProductsByWarehouseAsync',
    SALE_TODAY: 'Sale/Today',
    SALE_LAST_7_DAYS: 'Sale/Last7Days',
    SALE_LAST_30_DAYS: 'Sale/Last30Days',
    SALE_PAYMENT_METHOD_TODAY: 'Sale/PaymentMethodDay',
    SALE_BY_WAREHOUSE_PAYMENT_METHOD: 'Sale/GetSaleWarehousePaymentMethods',
    SALE_TOP_SELLING: 'Sale/TopSelling',
    SALE_SALES_DELETE: 'Sale/SalesDelete',
    SALE_GET_PAYMENT_METHOD: 'Sale/GetSalesToBeInvoiced',

    // VOUCHER DOCUMENT TYPE MODULE
    LIST_SELECT_VOUCHERDOCUMENTTYPE: 'VoucherDocumentType/Select',

    // WAREHOUSE MODULE
    LIST_WAREHOUSES: 'Warehouse/Filters',
    LIST_SELECT_WAREHOUSES: 'Warehouse/Select',
    WAREHOUSE_BY_ID: 'Warehouse/',
    WAREHOUSE_REGISTER: 'Warehouse',
    WAREHOUSE_EDIT: 'Warehouse/',
    WAREHOUSE_REMOVE: 'Warehouse/',

}

export const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}