import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from '../models/login.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { endpoint, httpOptions } from '@shared/apis/endpoint';
import { map } from 'rxjs/operators';
import { BaseResponse } from '@shared/models/base-api-response.interface';
import { AlertService } from '@shared/services/alert.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: BehaviorSubject<string>;

  public get userToken(): string {
    return this.user.value;
  }

  constructor(
    private _http: HttpClient,
  ) {
    this.user = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem('token'))
    );
  }

  login(req: Login, authType: string): Observable<BaseResponse> {
    localStorage.setItem('authType', 'Interno')
    const requestUrl = `${environment.api}${endpoint.LOGIN}?authType=${authType}`
    return this._http.post<BaseResponse>(requestUrl, req, httpOptions).pipe(
      map((resp: BaseResponse) => {
        if (resp.isSuccess) {
          localStorage.setItem('token', JSON.stringify(resp.data));
          this.user.next(resp.data);
          Swal.fire({
            title: "Bienvenido!",
            text: 'a tu sistema de ventas',
            icon: "success",
            confirmButtonText: "Aceptar",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        return resp;
      })
    )
  }

  loginWithGoogle(credential: string, authType: string): Observable<BaseResponse> {
    localStorage.setItem('authType', 'Externo')
    const requestUrl = `${environment.api}${endpoint.LOGIN_GOOGLE}?authType=${authType}`
    return this._http.post<BaseResponse>(requestUrl, JSON.stringify(credential), httpOptions).pipe(
      map((resp: BaseResponse) => {
        if (resp.isSuccess) {
          localStorage.setItem('token', JSON.stringify(resp.data));
          this.user.next(resp.data);
          Swal.fire({
            title: "Bienvenido!",
            text: 'a tu sistema de ventas',
            icon: "success",
            confirmButtonText: "Aceptar",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        return resp;
      })
    )
  }

  loginClient(req: Login, authType: string): Observable<BaseResponse> {
    localStorage.setItem('authType', 'Externo')
    const requestUrl = `${environment.api}${endpoint.LOGIN_CLIENT}?authType=${authType}`
    return this._http.post<BaseResponse>(requestUrl, req, httpOptions).pipe(
      map((resp: BaseResponse) => {
        if (resp.isSuccess) {
          localStorage.setItem('token', JSON.stringify(resp.data));
          this.user.next(resp.data);
          Swal.fire({
            title: "Bienvenido!",
            text: 'Tienda online Bookshops',
            icon: "success",
            confirmButtonText: "Aceptar",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        return resp;
      })
    )
  }

  loginWithGoogleClient(credential: string, authType: string): Observable<BaseResponse> {
    localStorage.setItem('authType', 'Externo')
    const requestUrl = `${environment.api}${endpoint.LOGIN_GOOGLE_CLIENT}?authType=${authType}`
    return this._http.post<BaseResponse>(requestUrl, JSON.stringify(credential), httpOptions).pipe(
      map((resp: BaseResponse) => {
        if (resp.isSuccess) {
          localStorage.setItem('token', JSON.stringify(resp.data));
          this.user.next(resp.data);
          Swal.fire({
            title: "Bienvenido!",
            text: 'Tienda online Bookshops',
            icon: "success",
            confirmButtonText: "Aceptar",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        return resp;
      })
    )
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('authType');
    this.user.next(null);
    window.location.reload();
  }

  isTokenExpired(token: string): boolean {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = new Date(tokenData.exp * 1000);
    const currentTime = new Date();
    return expirationTime <= currentTime;
  }
}
