import { Injectable } from '@angular/core';
import icEdit from '@iconify/icons-ic/round-edit';
import icDelete from '@iconify/icons-ic/round-delete';
import icArrowDropDown from '@iconify/icons-ic/round-arrow-drop-down';
import icSearch from '@iconify/icons-ic/round-search';
import icClose from '@iconify/icons-ic/round-close';
import icName from '@iconify/icons-ic/round-badge';
import icDescription from '@iconify/icons-ic/round-description';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icMail from '@iconify/icons-ic/round-email';
import icViewHeadLine from "@iconify/icons-ic/twotone-view-headline";
import icLabel from "@iconify/icons-ic/twotone-label";
import icCalendar from "@iconify/icons-ic/twotone-calendar-today";
import icToday from "@iconify/icons-ic/round-today";
import icProvider from "@iconify/icons-ic/twotone-group";
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import icCategory from '@iconify/icons-ic/twotone-category';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icRefresh from '@iconify/icons-ic/round-refresh';
import icWarehouse from '@iconify/icons-ic/twotone-widgets';
import icManage from '@iconify/icons-ic/twotone-article';
import icProduct from '@iconify/icons-ic/twotone-inventory-2';
import icUpload from '@iconify/icons-ic/twotone-upload-file';
import icSales from '@iconify/icons-ic/twotone-point-of-sale';
import icCancel from '@iconify/icons-ic/twotone-cancel';
import icAdd from '@iconify/icons-ic/twotone-add-shopping-cart';
import icMin from '@iconify/icons-ic/twotone-remove';
import icAddDetail from '@iconify/icons-ic/twotone-plus';
import icRemove from '@iconify/icons-ic/twotone-remove-shopping-cart';
import icClient from '@iconify/icons-ic/twotone-supervisor-account';
import icCamera from '@iconify/icons-ic/twotone-camera';
import icBarcode from '@iconify/icons-ic/twotone-barcode';
import icAddPago from '@iconify/icons-ic/twotone-payments';
import icRemovePago from '@iconify/icons-ic/twotone-remove';
import icUsers from '@iconify/icons-ic/twotone-account-circle';
import icFactura from '@iconify/icons-ic/twotone-receipt';
import icDatos from '@iconify/icons-ic/twotone-verified-user';
import icCarrito from '@iconify/icons-ic/twotone-shopping-cart';
import icPedidos from '@iconify/icons-ic/twotone-border-color';
import icStore from '@iconify/icons-ic/twotone-store';
import icFinalizado from '@iconify/icons-ic/twotone-check';
import icStock from '@iconify/icons-ic/twotone-inventory';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  getIcon(icon: string) {
    if (icon === 'icStock') {
      return icStock;
    }
    if (icon === 'icFinalizado') {
      return icFinalizado;
    }
    if (icon === 'icEdit') {
      return icEdit;
    }
    if (icon === 'icDelete') {
      return icDelete;
    }
    if (icon === 'icAdd') {
      return icAdd;
    }
    if (icon === 'icArrowDropDown') {
      return icArrowDropDown
    }
    if (icon === 'icViewHeadLine') {
      return icViewHeadLine
    }
    if (icon === 'icLabel') {
      return icLabel;
    }
    if (icon === 'icCalendar') {
      return icCalendar
    }
    if (icon === 'icSearch') {
      return icSearch
    }
    if (icon === 'icDashboard') {
      return icDashboard
    }
    if (icon === 'icCategory') {
      return icCategory
    }
    if (icon === 'icProvider') {
      return icProvider
    }
    if (icon === 'icClose') {
      return icClose
    }
    if (icon === 'icName') {
      return icName
    }
    if (icon === 'icDescription') {
      return icDescription
    }
    if (icon === 'icVisibility') {
      return icVisibility
    }
    if (icon === 'icVisibilityOff') {
      return icVisibilityOff
    }
    if (icon === 'icMail') {
      return icMail
    }
    if (icon === 'icCloudDownload') {
      return icCloudDownload
    }
    if (icon === 'icToday') {
      return icToday
    }
    if (icon === 'icRefresh') {
      return icRefresh
    }
    if (icon === 'icWarehouse') {
      return icWarehouse
    }
    if (icon === 'icManage') {
      return icManage
    }
    if (icon === 'icProduct') {
      return icProduct
    }
    if (icon === 'icUpload') {
      return icUpload
    }
    if (icon === 'icSales') {
      return icSales
    }
    if (icon === 'icCancel') {
      return icCancel
    }
    if (icon === 'icMin') {
      return icMin
    }
    if (icon === 'icAddDetail') {
      return icAddDetail
    }
    if (icon === 'icRemove') {
      return icRemove
    }
    if (icon === 'icClient') {
      return icClient
    }
    if (icon === 'icCamera') {
      return icCamera
    }
    if (icon === 'icBarcode') {
      return icBarcode
    }
    if (icon === 'icAddPago') {
      return icAddPago
    }
    if (icon === 'icRemovePago') {
      return icRemovePago
    }
    if (icon === 'icUsers') {
      return icUsers
    }
    if (icon === 'icFactura') {
      return icFactura
    }
    if (icon === 'icDatos') {
      return icDatos
    }
    if (icon === 'icCarrito') {
      return icCarrito
    }
    if (icon === 'icPedidos') {
      return icPedidos
    }
    if (icon === 'icStore') {
      return icStore
    }
  }

}
